import CoreApi from '../core-api'
import RemoteApi from '../../../panels/commons/remote-api'
import { DEFAULT_RESTRICTIONS, PremiumRestriction } from '../../../constants/premium'
import { ASCEND_PLAN } from '../../../panels/commons/constants/ascend'

export default class PremiumApi {
  private boundEditorSDK: any
  private coreApi: CoreApi
  private experiments: any
  private remoteApi: RemoteApi
  private cachedData: any //TODO: [PERFORMANCE]: Move to react query and use it in other places too

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.remoteApi = remoteApi
    this.experiments = experiments
    this.cachedData = {}
  }

  public async isTopPremium() {
    try {
      const isTemplate = await this.coreApi.isTemplate()

      if (isTemplate) {
        return false
      }

      if (this.cachedData['isTopPremium'] !== undefined) {
        return this.cachedData['isTopPremium']
      }

      const isTopPremium = await this.remoteApi.isTopPremium()
      this.cachedData['isTopPremium'] = isTopPremium
      return isTopPremium
    } catch (err) {
      return false
    }
  }

  public async getPremiumRestrictions(): Promise<{ restrictions: PremiumRestriction, currentAscendPlan: ASCEND_PLAN }> {
    const fallbackResponse = Promise.resolve({ restrictions: DEFAULT_RESTRICTIONS, currentAscendPlan: ASCEND_PLAN.FREE })

    try {
      const isTemplate = await this.coreApi.isTemplate()

      if (isTemplate) {
        return fallbackResponse
      } else {
        if (this.cachedData['restrictions']) {
          return this.cachedData['restrictions']
        }

        const res = await this.remoteApi.getPremiumRestrictions()

        if (res) {
          this.cachedData['restrictions'] = res
          return res
        }

        return fallbackResponse
      }
    } catch (err) {
      return fallbackResponse
    }
  }

  public async getCurrentAscendPlan() {
    if (this.cachedData['currentAscendPlan'] !== undefined) {
      return this.cachedData['currentAscendPlan']
    }
    try {
      const currentAscendPlan = await this.remoteApi.getCurrentAscendPlan()
      this.cachedData['currentAscendPlan'] = currentAscendPlan
      return currentAscendPlan
    } catch (err) {
      return ASCEND_PLAN.FREE
    }
  }
}

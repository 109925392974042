import { getValidCollectionId, isAnyField, isInputField, getPrimaryConnection } from '../utils'
import { undoable, withBi, withSync } from '../decorators'
import { EVENTS } from '../../../constants/bi'
import { FieldOption, FormField, FileType, ComponentConfig } from '../../../constants/api-types'
import * as _ from 'lodash'
import CoreApi from '../core-api'
import {
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
  FIELDS,
  FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD,
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
  THANK_YOU_STEP_ROLE,
  FIELDS_ROLES,
  ROLE_FORM,
} from '../../../constants/roles'
import {
  CRM_LABEL_MAX_LENGTH,
  CustomField,
  FieldPreset,
  FormsFieldPreset,
  Field,
} from '../../../constants/field-types'
import { FormPreset } from '../../../constants/form-types'
import { createSuffixedName } from '../../../utils/utils'
import {
  createField,
  fetchSubmitButtonSchema,
  fetchHiddenMessage,
  fetchLoginLinkSchema,
} from '../services/form-service'
import { GROUP_COMPONENT, MOBILE_CONTAINER } from './constants/container-types'
import { commonStyles } from '../services/form-style-service'
import {
  FieldExtraData,
  FieldProperties,
  allowCollectionSync,
  ALL_FIELDS_DATA,
} from '../preset/fields/field-types-data'
import { FormPlugin } from '../../../constants/plugins'
import { CUSTOM_FIELD } from '../../../constants/crm-types-tags'
import { getFieldName } from '../../../panels/adi-panel/utils'
import { getFormPreset } from '../preset/preset-service'
import { COMPONENT_TYPES } from '../../../constants/component-types'
import translations from '../../../utils/translations'

import * as submitButtonStructure from '../../../assets/presets/submit-button.json'
import * as signupButtonStructure from '../../../assets/presets/signup-button.json'
import * as hiddenMessageStructure from '../../../assets/presets/hidden-message.json'
import * as registrationFormMessageStructure from '../../../assets/presets/registration-form-message.json'
import * as registrationLoginLinkStructure from '../../../assets/presets/login-link.json'
import { FORMS_APP_DEF_ID } from '../../../constants'
import {
  DEFAULT_CATEGORIES,
  REGISTRATION_FORM_CATEGORY,
  RECOMMENDED_FIELDS,
  FIELD_GROUPS,
  CUSTOM_FIELDS,
  FieldConfig,
} from '../../../panels/manage-fields-panel/constants/manage-fields-constants'
import {
  getDuplicatedFieldConfig,
  getDefaultLabel,
  getFieldsLeft,
  getDefaultFieldName,
} from './utils'
import { PanelName } from '../manage-panels/consts/panel-names'
import { BillingPanelReferrer, upgradeAlertType } from '../../../constants/premium'
import Experiments from '@wix/wix-experiments'
import { SUPPORTED_COMPONENT_TYPES_IN_RESPONSIVE, ADD_FIELD_FLOW } from './constants'
import {
  findNewFieldStackLayout,
  calcUpdatesForStackFieldsByOrder,
  getOrderFromResponsiveLayout,
} from '../layout-panel/utils'
import { getMainCrmTypesCustomFields } from '../contact-sync/utils'
import { ConnectFieldPanelState } from '../../../panels/connect-field-panel/reducer'
import { convertPluginsToFormsPlugins } from '../plugins/utils'
import { FedopsLogger } from '@wix/fedops-logger'

// TODO: Move to registration plugin api
const ROLE_LINK_TO_LOGIN = FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG

const CRUCIAL_ROLES = [
  ROLE_SUBMIT_BUTTON,
  ROLE_LINK_TO_LOGIN,
  ROLE_MESSAGE,
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
]

const COMPONENTS_TO_REPOSITION_AFTER_ADD_FIELD = [
  FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG,
  ROLE_SUBMIT_BUTTON,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
  ROLE_MESSAGE,
]

export const SPACE_BETWEEN_FIELDS = 32

export default class FieldSettingsApi {
  private biLogger: any
  private fedopsLogger: FedopsLogger
  private boundEditorSDK: any
  private coreApi: CoreApi
  private remoteApi: any
  private ravenInstance
  private experiments: Experiments

  constructor(
    boundEditorSDK,
    coreApi: CoreApi,
    remoteApi,
    { biLogger, ravenInstance, experiments, fedopsLogger }
  ) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.fedopsLogger = fedopsLogger
    this.remoteApi = remoteApi
    this.ravenInstance = ravenInstance
    this.experiments = experiments
  }

  private async _getCategories(plugins: FormPlugin[]): Promise<string[]> {
    let categories: string[] = _.values(DEFAULT_CATEGORIES)

    const isRegistrationForm = _.includes(plugins, FormPlugin.REGISTRATION_FORM)
    const isGetSubscribers = _.includes(plugins, FormPlugin.GET_SUBSCRIBERS)

    if (isRegistrationForm) {
      categories = [REGISTRATION_FORM_CATEGORY, ...categories]
    }

    const filterCategories = category => {
      if (isGetSubscribers) {
        if (category === DEFAULT_CATEGORIES.subscription) {
          return false
        }
      }

      return category !== DEFAULT_CATEGORIES.none
    }

    return _.filter(categories, category => filterCategories(category))
  }

  private _getCustomFields(customFields) {
    return _.map(customFields, ({ id, name, fieldType }) => ({
      value: FormsFieldPreset[`CRM_${fieldType.toUpperCase()}`],
      name,
      customFieldId: id,
      category: FIELD_GROUPS.RECOMMENDED,
      crmType: CUSTOM_FIELD,
    }))
  }

  private _getGeneralFields(): Field[] {
    const fields = _.map(CUSTOM_FIELDS, (field: FieldConfig) => {
      const {
        id,
        isPremium,
        dependsOn,
        hideTranslationPostfix,
        showTooltip,
        category,
        subCategory,
      } = field

      const mappedField: Field = {
        value: id,
        name: hideTranslationPostfix
          ? translations.t(`fieldTypes.${id}`)
          : translations.t(`fieldTypes.generalField`, { name: translations.t(`fieldTypes.${id}`) }),
        isPremium,
        dependsOn,
        subCategory,
        category,
      }

      if (showTooltip) {
        mappedField.tooltip = translations.t(`fieldTypes.${id}.tooltip`)
      }

      return mappedField
    }).filter(
      field =>
        field.value !== FormsFieldPreset.GENERAL_TIME_PICKER ||
        this.experiments.enabled('specs.cx.FormBuilderTimePicker')
    )

    const recommendedFields = _.map(RECOMMENDED_FIELDS, (type: FieldPreset) => ({
      value: type,
      name: translations.t(`fieldTypes.${type}`),
      category: FIELD_GROUPS.RECOMMENDED,
      customFieldId: undefined,
      crmType: ALL_FIELDS_DATA[type].crmType,
    }))

    return [...fields, ...recommendedFields]
  }

  private async _getNewFields(formComponentRef: ComponentRef, customFields): Promise<Field[]> {
    const newGeneralFields = this._getGeneralFields()
    const newCustomFields = this._getCustomFields(customFields)

    const newExtraFields = await this.coreApi.formsExtendApi({
      formComponentRef,
      api: 'fields.getNewFields',
    })

    const fields: Field[] = [...newGeneralFields, ...newCustomFields, ...newExtraFields.add]

    return _.filter(fields, field => !_.includes(newExtraFields.remove, field.value))
  }

  private _getFilteredFieldsAndCategories(fields: Field[], allCategories: string[]) {
    if (!this.coreApi.isResponsive()) {
      return { filteredFields: fields, filteredCategories: allCategories }
    }

    const categoriesWithFields = {}

    const filteredFields = _.filter(fields, field => {
      const componentType = ALL_FIELDS_DATA[field.value].properties.componentType

      if (_.includes(SUPPORTED_COMPONENT_TYPES_IN_RESPONSIVE, componentType)) {
        categoriesWithFields[field.category] = true

        if (field.subCategory) {
          categoriesWithFields[field.subCategory] = true
        }

        return true
      }
    })

    const categoriesNames = _.keys(categoriesWithFields)
    const filteredCategories = _.intersection(allCategories, categoriesNames)

    return { filteredFields, filteredCategories }
  }

  public async loadInitialPanelData({ componentRef, preset, plugins }: { componentRef: ComponentRef, preset: string, plugins: FormPlugin[] }) {
    return Promise.all([
      this._getCategories(plugins),
      this.getCustomFields(),
      this.getFieldsSortByXY(componentRef),
      this.coreApi.style.getFieldsCommonStylesGlobalDesign(componentRef),
      this.coreApi.premium.getPremiumRestrictions(),
      this.coreApi.getComponentConnection(componentRef)
    ]).then(
      ([
        allCategories,
        customFields,
        fieldsOnStage,
        commonStyles,
        { restrictions, currentAscendPlan },
        formComponentConnection
      ]) =>
        this._getNewFields(componentRef, customFields).then(async fields => {
          const isRegistrationForm = _.includes(plugins, FormPlugin.REGISTRATION_FORM)
          const selectedTab = isRegistrationForm
            ? REGISTRATION_FORM_CATEGORY
            : DEFAULT_CATEGORIES.recommended

          const { filteredFields, filteredCategories } = this._getFilteredFieldsAndCategories(
            fields,
            allCategories
          )

          return {
            preset,
            fieldsOnStage,
            commonStyles,
            restrictions,
            currentAscendPlan,
            plugins,
            formComponentConfig: formComponentConnection.config,
            appDefId: FORMS_APP_DEF_ID,
            categories: filteredCategories,
            fields: filteredFields,
            showIntroForCategories: [DEFAULT_CATEGORIES.recommended, REGISTRATION_FORM_CATEGORY],
            selectedTab,
          }
        })
    )
  }

  public async loadInitialConnectFieldData(
    fieldComponentRef: ComponentRef
  ): Promise<Partial<ConnectFieldPanelState>> {
    return Promise.all([
      this.getCustomFields(),
      this.getFieldsSortByXY(fieldComponentRef),
      this.coreApi.getFormId(fieldComponentRef),
      this.coreApi.getFormConfigData(fieldComponentRef),
    ]).then(([customFields, fields, formId, { plugins, preset }]) => {
      const field = fields.find(f => f.componentRef.id === fieldComponentRef.id)

      return {
        componentRef: fieldComponentRef,
        fields,
        customFields: customFields || [],
        formId,
        plugins,
        preset,
        fieldType: field.fieldType,
        crmLabel: field.crmLabel,
        lastValidCrmLabel: field.crmLabel,
        crmType: field.crmType,
        crmTag: field.crmTag,
        customFieldId: field.customFieldId,
        isCustomField: field.crmType === CUSTOM_FIELD,
        otherFieldsNames: _.pull(
          _.map(fields, (field: any) => field.crmLabel),
          field.crmLabel
        ),
        // contact sync new panel
        mainCrmTypesCustomFields: getMainCrmTypesCustomFields(),
        customFieldName: field.customFieldName,
        componentType: field.componentType,
      }
    })
  }

  private _sumOffsetsWithMap(containers: ComponentRef[], componentsLayoutMap) {
    const containersOffset = containers.reduce(
      (offsetAccumulator, currentValue) => {
        const containerLayout = componentsLayoutMap[currentValue.id].layout

        return {
          x: offsetAccumulator.x + containerLayout.x,
          y: offsetAccumulator.y + containerLayout.y,
        }
      },
      { x: 0, y: 0 }
    )

    return containersOffset
  }

  public async getRawFields(componentRef: ComponentRef): Promise<ComponentRef[]> {
    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    return this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
  }

  private async _getFieldsSortByXY(
    componentRef: ComponentRef,
    { allFieldsTypes } = { allFieldsTypes: false }
  ): Promise<FormField[]> {
    const childrenRefs = await this.getRawFields(componentRef)

    const fields = await this._getFields(
      childrenRefs.filter(x => !!x),
      allFieldsTypes
    )

    const fieldsRefs = fields.map(({ componentRef }) => componentRef)

    const fieldsWithAncestors: {
      componentRef: ComponentRef
      ancestors: ComponentRef[]
    }[] = await Promise.all(
      fieldsRefs.map(async componentRef => {
        const ancestors = await this.boundEditorSDK.components.getAncestors({ componentRef })
        return {
          componentRef,
          ancestors,
        }
      })
    )

    const flattenAncestorsRefs = _.flatten(fieldsWithAncestors.map(({ ancestors }) => ancestors))
    const uniqueAncestorsRefs = _.uniqBy(flattenAncestorsRefs, ({ id }) => id)

    const allComponentsRefs = [
      ...fieldsRefs,
      ...uniqueAncestorsRefs,
    ]

    const allComponentsLayout = await this.boundEditorSDK.components.get({
      componentRefs: allComponentsRefs,
      properties: ['layout', 'layoutResponsive'],
    })

    const uniqueAncestorsWithTypes = await this.boundEditorSDK.components.get({
      componentRefs: uniqueAncestorsRefs,
      properties: ['componentType'],
    })

    const fieldsWithAncestorsWithTypes = fieldsWithAncestors.map(({ componentRef, ancestors }) => ({
      componentRef,
      ancestors: ancestors.map(ancestorComponentRef =>
        uniqueAncestorsWithTypes.find(ancestorWithType => ancestorWithType.componentRef.id === ancestorComponentRef.id)
      ),
    }))

    const allComponentsById = _.keyBy(allComponentsLayout, 'componentRef.id')

    const allFieldsWithAncestorsById = _.keyBy(fieldsWithAncestorsWithTypes, 'componentRef.id')

    const enrichedFields = fields.map(field => {
      const parentContainers = allFieldsWithAncestorsById[field.componentRef.id].ancestors.filter(ancestor =>
        [MOBILE_CONTAINER, GROUP_COMPONENT].some(type => type === ancestor.componentType)
      )
      const fieldLayout = allComponentsById[field.componentRef.id].layout || {
        x: 0,
        y: 0,
        height: 0,
        width: 0,
      }

      if (parentContainers.length > 0) {
        const containersOffset = this._sumOffsetsWithMap(
          parentContainers.map(container => container.componentRef),
          allComponentsById
        )

        fieldLayout.x += containersOffset.x
        fieldLayout.y += containersOffset.y
      }

      const { x, y, height, width } = fieldLayout
      const layoutResponsive = allComponentsById[field.componentRef.id].layoutResponsive || {}

      return _.merge({ x, y, height, width }, field, { layoutResponsive }, {
        parentComponentRef: _.get(
          allFieldsWithAncestorsById[field.componentRef.id],
          'ancestors[0].componentRef'
        ),
      })
    })

    return this._sortFields({ componentRef, fields: enrichedFields, ancestorsMap: allFieldsWithAncestorsById })
  }

  private async _sortFields({ componentRef, fields, ancestorsMap }) {
    const componentConnection = await this.coreApi.getComponentConnection(componentRef)
    const plugins = _.get(componentConnection, 'config.plugins')
    const isMultiStepForm = !!_.find(plugins, { id: FormPlugin.MULTI_STEP_FORM })

    // TODO: Order based on stack order, HOW??? each label can have more than one order :| (breakpoints)

    if (isMultiStepForm) {
      const stepsData: StepData[] = await this.coreApi.steps.getSteps(componentRef)

      const mapFieldContainerToIndex = ancestors => {
        const stepContainer = _.find(
          ancestors,
          ancestor => ancestor.componentType === COMPONENT_TYPES.FORM_STATE
        )

        if (!stepContainer) {
          return 0
        }

        return _.findIndex(stepsData, stepData =>
          _.isEqual(stepData.componentRef, stepContainer.componentRef)
        )
      }

      const fieldsWithContainerIndex = fields.map(field => {
        const fieldContainerIndex = mapFieldContainerToIndex(
          ancestorsMap[field.componentRef.id].ancestors
        )
        return _.merge({}, { fieldContainerIndex }, field)
      })

      return _.sortBy(fieldsWithContainerIndex, ['fieldContainerIndex', 'y', 'x'])
    } else {
      return _.sortBy(fields, ['y', 'x'])
    }
  }

  public getContainerFields(componentRef: ComponentRef): Promise<ComponentRef[]> {
    return this.coreApi.findChildComponentsByRole(componentRef, FIELDS_ROLES)
  }

  public async getFieldsSortByXY(
    componentRef: ComponentRef,
    { allFieldsTypes } = { allFieldsTypes: false }
  ): Promise<FormField[]> {
    return this._getFieldsSortByXY(componentRef, { allFieldsTypes })
  }

  private async _normalizeCrmLabel(
    currentFieldRef: ComponentRef,
    crmLabel: string
  ): Promise<string> {
    const allFieldRefs = await this.getRawFields(currentFieldRef)
    const otherFieldRefs = _.filter(allFieldRefs, fieldRef => !_.isEqual(fieldRef, currentFieldRef))
    const otherCrmLabels = await Promise.all(
      otherFieldRefs.map(async fieldRef => {
        const fieldConnection = await this.coreApi.getComponentConnection(fieldRef)
        return _.get(fieldConnection, 'config.crmLabel')
      })
    )
    return createSuffixedName(otherCrmLabels, crmLabel)
  }

  public async getAndUpdateCrmLabel(componentRef: ComponentRef, previousFieldData): Promise<void> {
    const [connection, { data: currentFieldData }] = await Promise.all([
      this.coreApi.getComponentConnection(componentRef),
      this._getFieldPropertiesAndData(componentRef),
    ])
    const fieldType = _.get(connection, 'config.fieldType')

    const previousDefaultLabel = fieldType && getDefaultLabel({ ...previousFieldData, fieldType })
    const currentDefaultLabel = fieldType && getDefaultLabel({ ...currentFieldData, fieldType })
    const newCrmLabel = currentDefaultLabel || currentFieldData.type

    if (_.isEqual(previousDefaultLabel, currentDefaultLabel) || !newCrmLabel)
      return Promise.resolve()

    const suffixedNewCrmLabel = await this._normalizeCrmLabel(componentRef, newCrmLabel)
    return this.updateCrmLabel(componentRef, suffixedNewCrmLabel)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED })
  public async updateCrmLabel(componentRef: ComponentRef, crmLabel: string, _biData = {}) {
    return this._updateCrmLabel(componentRef, crmLabel)
  }

  private async _updateCrmLabel(componentRef: ComponentRef, crmLabel: string) {
    crmLabel = crmLabel.substring(0, CRM_LABEL_MAX_LENGTH)
    const {
      config: { collectionFieldKey },
      controllerRef,
    } = await this.coreApi.getComponentConnection(componentRef)
    await this.coreApi.setComponentConnection(componentRef, { crmLabel })

    const updateCollection = async () => {
      const collectionId = await this._getCollectionId(controllerRef)
      if (!collectionId) {
        return
      }
      return this.coreApi.collectionsApi.updateField(collectionId, collectionFieldKey, crmLabel)
    }
    return updateCollection()
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.adiEditFieldPanel.CHANGE_FIELD_TITLE })
  public async changeLabelADI(
    componentRef: ComponentRef,
    label: string,
    newName: string,
    _biData = {}
  ) {
    await this._changeLabel(componentRef, label)
    return this._updateCrmLabel(componentRef, newName)
  }

  @undoable()
  public async changeLabel(componentRef: ComponentRef, label: string) {
    return this._changeLabel(componentRef, label)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED })
  public async showLabelChanged(componentRef: ComponentRef, showLabel: boolean, _biData = {}) {
    await this._showLabelChanged(componentRef, showLabel)
  }

  private async _showLabelChanged(componentRef: ComponentRef, showLabel: boolean) {
    if (showLabel) {
      const {
        config: { label },
      } = await this.coreApi.getComponentConnection(componentRef)

      return this.boundEditorSDK.components.data.update({
        componentRef,
        data: { label },
      })
    } else {
      return this.boundEditorSDK.components.data.update({
        componentRef,
        data: { label: '' },
      })
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.VALUE_UPDATED })
  public async showLabelChangedForAllFields(
    componentRef: ComponentRef,
    fields: {
      name: string
      componentRef: ComponentRef
    }[],
    showTitles: boolean,
    _biData = {}
  ) {
    const labelUpdates = fields.map(field => this._showLabelChanged(field.componentRef, showTitles))
    const namesUpdates = fields.map(field => this._updateCrmLabel(field.componentRef, field.name))
    await Promise.all([...labelUpdates, ...namesUpdates])
    await this.coreApi.layout.updateFieldsLayoutADI(componentRef, { showTitles })
  }

  @undoable()
  public async changeUploadFileLabelADI(
    componentRef: ComponentRef,
    buttonLabel: string,
    newName: string
  ) {
    await this._changeUploadFileLabel(componentRef, buttonLabel)
    return this._updateCrmLabel(componentRef, newName)
  }

  @undoable()
  public changeUploadFilePlaceholder(
    componentRef: ComponentRef,
    placeholderLabel: FieldPlaceholder
  ) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { placeholderLabel },
    })
  }

  @undoable()
  public async changePlaceholderADI(
    componentRef: ComponentRef,
    placeholder: FieldPlaceholder,
    newName: string
  ) {
    await this._changePlaceholder(componentRef, placeholder)
    return this._updateCrmLabel(componentRef, newName)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.TOGGLE_REQUIRED_FIELD })
  public changeRequired(componentRef: ComponentRef, required: boolean, _biData = {}) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { required },
    })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.SELECT_FIELD_TO_CONNECT })
  public setComponentConnection(connectToRef: ComponentRef, connectionConfig, _biData = {}) {
    return this.coreApi.setComponentConnection(connectToRef, connectionConfig)
  }

  public getCustomFields() {
    return this.remoteApi.getCustomFields()
  }

  public async createCustomField(componentRef: ComponentRef, field: CustomField) {
    const { id } = await this.remoteApi.createCustomField(field)
    await this.coreApi.setComponentConnection(componentRef, {
      customFieldId: id,
      customFieldName: field.name,
      crmTag: undefined,
    })
    return id
  }

  public getRestrictedKeywords() {
    return this.remoteApi.getRestrictedKeywords().catch(() => [])
  }

  private async _getFields(
    componentRefs: ComponentRef[],
    allFieldsTypes: boolean = false
  ): Promise<FormField[]> {
    if (componentRefs.length === 0) {
      return []
    }
    const components = await this.boundEditorSDK.components.get({
      componentRefs: componentRefs,
      properties: ['props', 'data', 'connections', 'componentType'],
    })
    const fields = await Promise.all<FormField>(
      components.map(async component => {
        const comp = {
          ...component,
          connection: getPrimaryConnection(_.get(component, 'connections')),
        }
        const field = await this._getField(comp, allFieldsTypes)
        return field
      })
    )
    return fields.filter(x => !!x)
  }

  public async getField(
    componentRef: ComponentRef,
    allFieldsTypes: boolean = false
  ): Promise<FormField> {
    const connection = await this.coreApi.getComponentConnection(componentRef)
    const { componentType, props, data } = await this._getFieldPropertiesAndData(componentRef)
    return this._getField({ componentType, connection, props, data, componentRef }, allFieldsTypes)
  }

  private async _getField(
    { componentType, connection, props, data, componentRef },
    allFieldsTypes
  ): Promise<FormField> {
    const actualProps = props || {}
    const actualData = data || {}
    const isValidFieldPred: (role: string) => boolean = allFieldsTypes ? isAnyField : isInputField
    const {
      config: {
        crmLabel,
        crmType,
        crmTag,
        customFieldId,
        customFieldName,
        fieldType,
        collectionFieldKey,
        collectionFieldType,
        label: labelFromConnection,
      },
      role,
    } = connection

    if (!isValidFieldPred(role)) {
      return null
    }

    const { placeholder: propPlaceholder, ...restProps } = actualProps

    const {
      placeholder: dataPlaceholder,
      titleText,
      clearButtonText,
      buttonLabel,
      label: labelFromData,
      checked,
      options,
      value,
      placeholderLabel,
    } = actualData

    const label = labelFromData || labelFromConnection
    const placeholder = dataPlaceholder || propPlaceholder || placeholderLabel
    let defaultLabel

    if (isInputField(role)) {
      defaultLabel = getDefaultLabel({
        titleText,
        buttonLabel,
        label,
        placeholder,
        fieldType,
      })

      await this._updateLabelConnection({
        componentRef,
        label: labelFromData,
        defaultLabel,
        labelFromConnection,
      })
    }

    return {
      componentType,
      componentRef,
      crmLabel,
      crmType,
      crmTag,
      fieldType,
      customFieldId,
      customFieldName,
      collectionFieldKey,
      collectionFieldType,
      checked,
      role,
      label: label || defaultLabel,
      placeholder,
      showLabel: !!labelFromData,
      buttonLabel,
      titleText,
      clearButtonText,
      options,
      defaultValue: value,
      ...restProps,
    }
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.manageFieldsPanel.DUPLICATE_FIELD })
  public async duplicateField(
    componentRef: ComponentRef,
    field: FormField,
    preset: FormPreset,
    plugins: FormPlugin[],
    { extraData, commonStyles },
    _biData = {}
  ) {
    const [
      {
        style,
        data,
        layout: { height, width },
      },
    ] = await this.boundEditorSDK.components.get({
      componentRefs: [field.componentRef],
      properties: ['style', 'data', 'layout'],
    })
    const { connectToRef, controllerRef } = await this._addField({
      componentRef,
      preset,
      plugins,
      fieldType: field.fieldType,
      extraData: _.merge({}, extraData, {
        data,
        layout: { height, width },
      }),
      commonStyles,
    })
    await this.boundEditorSDK.components.style.update({
      componentRef: connectToRef,
      style: _.get(style, 'style.properties'),
    })
    return { connectToRef, controllerRef }
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.addFieldPanel.SELECT_FIELD_TO_ADD,
    endEvid: EVENTS.PANELS.addFieldPanel.ADD_FIELD_COMPLETE,
  })
  public async addField(
    componentRef: ComponentRef,
    formComponentConfig: ComponentConfig,
    {
      fieldType,
      extraData,
      commonStyles,
      flow,
    }: {
      fieldType: FieldPreset
      extraData: FieldExtraData
      commonStyles: commonStyles
      flow?: ADD_FIELD_FLOW
    },
    _biData = {}
  ) {
    let fieldData

    const preset = _.get(formComponentConfig, 'preset')
    const plugins = convertPluginsToFormsPlugins(_.get(formComponentConfig, 'plugins', []))

    if (this.coreApi.isResponsive()) {
      fieldData = await this._addResponsiveField({
        componentRef,
        preset,
        plugins,
        fieldType,
        extraData,
        commonStyles,
      })
    } else {
      this.fedopsLogger.interactionStarted('add-classic-new-field')
      fieldData = await this._addField({
        componentRef,
        preset,
        plugins,
        fieldType,
        extraData,
        commonStyles,
        flow,
      })
      this.fedopsLogger.interactionEnded('add-classic-new-field')
    }

    let updatedFields

    // TODO: Remove this fetch and return FormField structure to avoid unnecessary get fields
    if (flow === ADD_FIELD_FLOW.ADD_NEW_FIELD) {
      updatedFields = await this.getFieldsSortByXY(componentRef)
    }

    this.boundEditorSDK.selection.locateAndHighlightComponentByCompRef({
      compRef: fieldData.connectToRef,
    }).then(() => {
      setTimeout(() => this.boundEditorSDK.selection.clearHighlights(), 500)
    })

    return { fieldData, updatedFields }
  }

  @undoable()
  @withBi({
    startEvid: EVENTS.PANELS.addFieldPanel.SELECT_FIELD_TO_ADD,
    endEvid: EVENTS.PANELS.addFieldPanel.ADD_FIELD_COMPLETE,
  })
  @withSync()
  public async addFieldADI(
    containerComponent: ComponentRef,
    field: FieldPreset,
    showLabel: boolean,
    showFieldsTitles: boolean,
    plugins: FormPlugin[] = [],
    _biData: object = {}
  ) {
    this.fedopsLogger.interactionStarted('add-adi-new-field')
    const fieldProperties: FieldProperties = ALL_FIELDS_DATA[field].properties
    _.set(fieldProperties, 'extraData.connectionConfig.fieldType', field)
    const formConfig = await this.coreApi.getComponentConnection(containerComponent)
    if (!formConfig) {
      return
    }
    const preset = formConfig.config.preset
    const commonStyles = await this.coreApi.style.getFieldsCommonStylesGlobalDesign(
      containerComponent
    )

    const label = _.get(fieldProperties, 'extraData.data.label')
    const placeholder = _.get(fieldProperties, 'extraData.data.placeholder')
    const buttonLabel = _.get(fieldProperties, 'extraData.data.buttonLabel')
    const crmLabel = _.get(fieldProperties, 'extraData.connectionConfig.crmLabel')

    const fieldName = getFieldName({
      label,
      placeholder,
      buttonLabel,
      showLabel,
      crmLabel,
      fieldType: field,
    })

    if (!showLabel && label) {
      _.set(fieldProperties, 'extraData.connectionConfig.label', label)
      _.set(fieldProperties, 'extraData.data.label', '')
    }

    _.set(
      fieldProperties,
      'extraData.connectionConfig.crmLabel',
      fieldName.substring(0, CRM_LABEL_MAX_LENGTH)
    )

    const customFieldId = await this.getCustomFieldForField(fieldProperties.extraData)

    if (customFieldId) {
      _.set(fieldProperties, 'extraData.connectionConfig.customFieldId', customFieldId)
    }

    try {
      const { width, height, inputHeight } = await this._overrideADILayout(
        containerComponent,
        preset,
        fieldProperties.componentType,
        showFieldsTitles
      )
      const newLayout = height && {
        props: { inputHeight },
        layout: { width, height },
      }
      const fieldData = await this._addField({
        componentRef: containerComponent,
        preset,
        plugins,
        commonStyles,
        extraData: _.merge(fieldProperties.extraData, newLayout),
        fieldType: field,
      })

      await this.coreApi.layout.updateFieldsLayoutADI(containerComponent, {
        showTitles: showFieldsTitles,
      })

      const newField = this._getField(
        {
          componentType: fieldProperties.componentType,
          componentRef: fieldData.connectToRef,
          props: fieldData.props,
          data: fieldData.data,
          connection: {
            isPrimary: true,
            config: fieldData.connectionConfig,
            role: fieldData.role,
          },
        },
        false
      )

      this.fedopsLogger.interactionEnded('add-adi-new-field')

      return newField
    } catch (ex) {}
  }

  public async fetchCustomFieldsByName() {
    try {
      const customFields = await this.remoteApi.getCustomFields()
      return _.groupBy(customFields, 'name')
    } catch (ex) {}
  }

  public async getCustomFieldForField(fieldData) {
    if (fieldData.connectionConfig.crmType !== CUSTOM_FIELD) {
      return
    }
    const fieldName = fieldData.connectionConfig.crmLabel
    const fieldCustomFieldsTypes =
      ALL_FIELDS_DATA[fieldData.connectionConfig.fieldType].metadata.customFields
    if (!fieldCustomFieldsTypes.length) {
      return
    }
    try {
      const customField =
        (await this.remoteApi.createCustomField({
          name: fieldName,
          fieldType: fieldCustomFieldsTypes[0],
        }))
      return _.get(customField, 'id')
    } catch (ex) {}
  }

  public async restoreField(formRef: ComponentRef, { data, role, config }) {
    const { controllerRef } = await this.coreApi.getComponentConnection(formRef)
    const field = { data, role, connectionConfig: config }
    return this.coreApi.addComponentAndConnect(field, controllerRef, formRef)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.SUCCESS_ACTION_TYPE_SELECTED })
  public async changeCheckboxLink(componentRef: ComponentRef, _biData = {}) {
    const { link: previousLink } = await this.boundEditorSDK.components.data.get({ componentRef })

    const link = await this.boundEditorSDK.editor.openLinkPanel({
      value: previousLink,
    })

    this.boundEditorSDK.components.data.update({ componentRef, data: { link } })
    const linkLocationValue = await this.boundEditorSDK.editor.utils.getLinkAsString({ link })

    return { link, linkLocationValue }
  }

  public updateCheckboxLinkData(componentRef: ComponentRef, linkLabel: string, link) {
    this.changeCheckboxLinkLabel(componentRef, linkLabel)

    if (!_.isEmpty(link)) this.updateCheckboxLink(componentRef, link)
  }

  @undoable()
  public changeCheckboxLinkLabel(componentRef: ComponentRef, linkLabel: string) {
    return this.boundEditorSDK.components.data.update({ componentRef, data: { linkLabel } })
  }

  public async getCheckboxLinkData(componentRef: ComponentRef) {
    const { link, linkLabel } = await this.boundEditorSDK.components.data.get({ componentRef })
    const linkLocationValue = link
      ? await this.boundEditorSDK.editor.utils.getLinkAsString({ link })
      : null

    return { link, linkLocationValue, linkLabel }
  }

  public updateCheckboxLink(componentRef: ComponentRef, link) {
    return this.boundEditorSDK.components.data.update({ componentRef, data: { link } })
  }

  @undoable()
  public removeCheckboxLinkData(componentRef: ComponentRef) {
    const emptyLinkData = { link: null, linkLabel: '' }
    return this.boundEditorSDK.components.data.update({ componentRef, data: emptyLinkData })
  }

  private _getFilterFieldsLayout(fieldsData: FormField[], roles: string[]): FormField[] {
    return _.filter(fieldsData, field => _.includes(roles, field.role))
  }

  private async _findNewFieldLocation(componentRef: ComponentRef, fieldsData?: FormField[]) {
    // TODO: [PERFORMANCE] This call is redundant, fieldsData already contains the layout data of each field. We just need to pass it to this function.
    const childLayouts = fieldsData
      ? this._getFilterFieldsLayout(fieldsData, FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD)
      : await this.coreApi.layout.getChildrenLayouts(
          componentRef,
          FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD
        )

    const lastLayout: any = _.maxBy(childLayouts, (field: any) => field.y)

    return {
      x: lastLayout ? lastLayout.x : 60,
      y: lastLayout ? lastLayout.y + lastLayout.height + SPACE_BETWEEN_FIELDS : 60,
    }
  }

  private async _overrideADILayout(
    componentRef: ComponentRef,
    presetKey: string,
    componentType: COMPONENT_TYPES,
    showTitles: boolean
  ) {
    const currentPreset = await getFormPreset(this.ravenInstance)(presetKey)
    const componentsLikeTextInput = [COMPONENT_TYPES.DATE_PICKER, COMPONENT_TYPES.COMBOBOX]
    const findComponentInPreset = componentType =>
      _.find(currentPreset['components'], { componentType })

    const componentInPreset =
      findComponentInPreset(componentType) ||
      (_.includes(componentsLikeTextInput, componentType) &&
        findComponentInPreset(COMPONENT_TYPES.TEXT_INPUT))
    const height: number = _.get(componentInPreset, 'layout.height')
    const inputHeight: number = _.get(componentInPreset, 'props.inputHeight')
    const hasLabel: boolean = !!_.get(componentInPreset, 'data.label')

    const labelHeight = showTitles && !hasLabel ? 15 : 0

    const { width } = await this.boundEditorSDK.components.layout.get({ componentRef })

    return { width, height: height + labelHeight, inputHeight }
  }

  private async _addResponsiveField({
    plugins,
    componentRef,
    preset,
    fieldType,
    extraData,
    commonStyles,
  }: {
    fieldType: FieldPreset
    extraData: FieldExtraData
    commonStyles: commonStyles
    plugins: FormPlugin[]
    componentRef: ComponentRef
    preset: string
  }) {
    const fieldsData = await this.coreApi.fields.getFieldsSortByXY(componentRef, {
      allFieldsTypes: true,
    })
    const newFieldLayout = findNewFieldStackLayout(fieldsData)
    const mergedLayout = _.merge({}, newFieldLayout, extraData.layoutResponsive)
    const fieldStructure = createField({
      preset,
      fieldType,
      extraData,
      commonStyles,
      fieldsData,
      isResponsive: this.coreApi.isResponsive(),
      layout: mergedLayout,
      plugins,
    })

    const { controllerRef, config } = await this.coreApi.getComponentConnection(componentRef)
    const collectionId = _.get(config, 'collectionId')
    const collectionFieldKey = await this._getCollectionFieldKey(
      fieldStructure.connectionConfig,
      fieldsData
    )
    _.set(fieldStructure, 'connectionConfig.collectionFieldKey', collectionFieldKey)

    const { connectToRef } = await this.coreApi.addComponentAndConnect(
      fieldStructure,
      controllerRef,
      componentRef
    )

    await this._fixFieldsOrderAfterFieldAdded(
      componentRef,
      connectToRef,
      getOrderFromResponsiveLayout(mergedLayout)
    )

    this.addFieldToCollection(componentRef, fieldStructure.connectionConfig, collectionId)

    return {
      connectToRef,
      controllerRef,
      ...fieldStructure.data,
      role: fieldStructure.role,
      connectionConfig: fieldStructure.connectionConfig,
    }
  }

  private async _addField({
    plugins,
    componentRef,
    preset,
    fieldType,
    extraData,
    commonStyles,
    flow,
  }: {
    fieldType: FieldPreset
    extraData: FieldExtraData
    commonStyles: commonStyles
    plugins: FormPlugin[]
    componentRef: ComponentRef
    preset: string
    flow?: ADD_FIELD_FLOW
  }) {
    let containerRef = componentRef
    let fieldsContainerRef = componentRef
    const isMultiStepForm = _.includes(plugins, FormPlugin.MULTI_STEP_FORM)

    let fieldsData = await this.coreApi.fields.getFieldsSortByXY(fieldsContainerRef, {
      allFieldsTypes: true,
    })

    if (isMultiStepForm) {
      fieldsContainerRef = await this.coreApi.steps.getCurrentStepRef(containerRef)
      fieldsData = _.filter(
        fieldsData,
        field => _.get(field, 'parentComponentRef.id') === fieldsContainerRef.id
      )
    }

    const newFieldLayout = await this._findNewFieldLocation(fieldsContainerRef, fieldsData)
    const { width: formWidth } = await this.boundEditorSDK.components.layout.get({
      componentRef: containerRef,
    })
    const fieldStructure = createField({
      preset,
      fieldType,
      extraData,
      commonStyles,
      fieldsData,
      formWidth,
      layout: _.merge({}, newFieldLayout, extraData.layout),
      plugins,
    })

    const { controllerRef, config } = await this.coreApi.getComponentConnection(containerRef)
    const collectionId = _.get(config, 'collectionId')
    const collectionFieldKey = await this._getCollectionFieldKey(
      fieldStructure.connectionConfig,
      fieldsData
    )
    _.set(fieldStructure, 'connectionConfig.collectionFieldKey', collectionFieldKey)

    if (
      flow === ADD_FIELD_FLOW.ADD_NEW_FIELD &&
      this.experiments.enabled('specs.cx.FormBuilderContactsListTab')
    ) {
      fieldStructure.connectionConfig.crmLabel = getDefaultFieldName({
        fieldStructure,
        fieldsOnStage: fieldsData,
      })
    }

    const { connectToRef } = await this.coreApi.addComponentAndConnect(
      fieldStructure,
      controllerRef,
      fieldsContainerRef
    )

    await this._fixFormLayoutAfterFieldAdded({
      fieldComponentRef: connectToRef,
      fieldsContainerRef,
      fieldsData,
      containerRef,
      plugins,
    })

    this.addFieldToCollection(componentRef, fieldStructure.connectionConfig, collectionId)

    return {
      connectToRef,
      controllerRef,
      ...fieldStructure.data,
      role: fieldStructure.role,
      connectionConfig: fieldStructure.connectionConfig,
    }
  }

  private async _fixFieldsOrderAfterFieldAdded(
    formRef: ComponentRef,
    newFieldRef: ComponentRef,
    newFieldOrder: number
  ): Promise<void[]> {
    const responsiveLayouts = (
      await this.coreApi.layout.getStackChildrenResponsiveLayouts(formRef)
    ).filter(field => field.componentRef.id !== newFieldRef.id)
    const layoutUpdates = calcUpdatesForStackFieldsByOrder(responsiveLayouts, newFieldOrder)
    return Promise.all<void>(
      _.reverse(layoutUpdates).map(this.boundEditorSDK.responsiveLayout.update)
    )
  }

  private async _fixFormLayoutAfterFieldAdded({
    fieldComponentRef,
    fieldsContainerRef,
    fieldsData,
    containerRef,
    plugins,
  }: {
    fieldComponentRef: ComponentRef
    fieldsContainerRef: ComponentRef
    fieldsData: FormField[]
    containerRef: ComponentRef
    plugins: FormPlugin[]
  }) {
    const { height: fieldHeight, y } = await this.boundEditorSDK.components.layout.get({
      componentRef: fieldComponentRef,
    })

    const updatePositions = async roles => {
      const components = this._getFilterFieldsLayout(fieldsData, roles)

      return Promise.all(
        _.map(components, component => {
          return this.boundEditorSDK.components.layout.update({
            componentRef: component.componentRef,
            layout: { y: component.y + fieldHeight + SPACE_BETWEEN_FIELDS },
          })
        })
      )
    }

    const recenterInLightBoxIfNeeded = async plugins => {
      if (_.includes(plugins, FormPlugin.REGISTRATION_FORM)) {
        return this.coreApi.layout.centerComponentInsideLightbox(containerRef)
      }
    }

    const containerHeightChanged = await this.coreApi.layout.addHeightToContainerIfFieldCrossedLimit(
      fieldsContainerRef,
      fieldHeight + SPACE_BETWEEN_FIELDS,
      y,
      fieldsData
    )

    if (containerHeightChanged) {
      await updatePositions(COMPONENTS_TO_REPOSITION_AFTER_ADD_FIELD)
    }

    await recenterInLightBoxIfNeeded(plugins)
  }

  private async _getCollectionFieldKey(fieldConnectionConfig, formFields) {
    return (
      _.get(fieldConnectionConfig, 'collectionFieldKey') ||
      createSuffixedName(
        _.map(formFields, 'collectionFieldKey'),
        _.camelCase(_.get(fieldConnectionConfig, 'crmLabel')),
        ''
      )
    )
  }

  public async addFieldToCollection(componentRef, fieldConnectionConfig, collectionId) {
    if (!allowCollectionSync(fieldConnectionConfig.fieldType)) {
      return Promise.resolve()
    }

    const validCollectionId = getValidCollectionId(componentRef.id, collectionId)

    if (!validCollectionId) {
      return Promise.resolve()
    }

    return this.coreApi.collectionsApi.addFieldToCollection(
      validCollectionId,
      fieldConnectionConfig
    )
  }

  private async _showRestrictionPopupOnDuplicateField(
    controllerRef: ComponentRef,
    fieldComponentRef: ComponentRef
  ) {
    const formComponentRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
    await this.coreApi.removeComponentRef(fieldComponentRef)
    this.coreApi.managePanels.openPremiumBillingPanel(formComponentRef, {
      referrer: BillingPanelReferrer.DUPLICATE_FIELD_ALERT,
      alertType: upgradeAlertType.FIELDS_LIMIT,
    })
  }

  public async onDuplicateField(fieldComponentRef: ComponentRef) {
    const [fields, { restrictions }, { config: fieldConfig, controllerRef }] = await Promise.all([
      this.getFieldsSortByXY(fieldComponentRef),
      this.coreApi.premium.getPremiumRestrictions(),
      this.coreApi.getComponentConnection(fieldComponentRef),
    ])

    if (getFieldsLeft(fields.length, restrictions.fields.limit) < 0) {
      await this._showRestrictionPopupOnDuplicateField(controllerRef, fieldComponentRef)
      return
    }

    const validCollectionId = await this._getCollectionId(controllerRef)

    const updatedConfig = getDuplicatedFieldConfig(fields, fieldConfig)

    const collectionFieldKey = await this._getCollectionFieldKey(updatedConfig, fields)
    _.set(updatedConfig, 'collectionFieldKey', collectionFieldKey)

    this.addFieldToCollection(fieldComponentRef, updatedConfig, validCollectionId)

    return this.coreApi.setComponentConnection(fieldComponentRef, updatedConfig, false)
  }

  private _changePlaceholder(componentRef: ComponentRef, placeholder) {
    const updatePropPlaceholderPromise = this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { placeholder },
    })
    const updateDataPlaceholderPromise = this.boundEditorSDK.components.data.update({
      componentRef,
      data: { placeholder },
    })

    return Promise.all([updatePropPlaceholderPromise, updateDataPlaceholderPromise])
  }

  private _changeUploadFileLabel(componentRef: ComponentRef, buttonLabel) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { buttonLabel },
    })
  }

  private async _changeLabel(componentRef: ComponentRef, label: string) {
    await this.boundEditorSDK.components.data.update({
      componentRef,
      data: { label },
    })
    return this.coreApi.setComponentConnection(componentRef, { label })
  }

  private async _getCollectionId(controllerRef) {
    const componentRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
    if (!componentRef) {
      return
    }
    const {
      config: { collectionId },
    } = await this.coreApi.getComponentConnection(componentRef)
    return getValidCollectionId(componentRef.id, collectionId)
  }

  private async _getFieldPropertiesAndData(componentRef: ComponentRef) {
    const res = await this.boundEditorSDK.components.get({
      componentRefs: componentRef,
      properties: ['props', 'data', 'componentType'],
    })

    return res[0]
  }

  private _updateLabelConnection({ componentRef, label, defaultLabel, labelFromConnection }) {
    if (labelFromConnection) {
      return
    }

    return this.coreApi.setComponentConnection(componentRef, { label: label || defaultLabel })
  }

  @undoable()
  public onDateFormatChange(componentRef: ComponentRef, newFormat: string) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { dateFormat: newFormat },
    })
  }

  @undoable()
  public onFileUploaderTypeChanged(componentRef: ComponentRef, newType: FileType) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { filesType: newType },
    })
  }

  @undoable()
  public onFileUploaderTogglePlaceholderChanged(componentRef: ComponentRef, toggleValue: boolean) {
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props: { showPlaceholder: toggleValue },
    })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.adiEditFieldPanel.ADD_NEW_CHOICE })
  public async addFieldOption(componentRef: ComponentRef, newOptions: FieldOption[], _biData = {}) {
    return this._editFieldsOptions(componentRef, newOptions)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.adiEditFieldPanel.DELETE_CHOICE })
  public async deleteFieldOption(
    componentRef: ComponentRef,
    newOptions: FieldOption[],
    _biData = {}
  ) {
    return this._editFieldsOptions(componentRef, newOptions)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.adiEditFieldPanel.EDIT_CHOICE_DONE })
  public async editFieldOptionName(
    componentRef: ComponentRef,
    newOptions: FieldOption[],
    _biData = {}
  ) {
    return this._editFieldsOptions(componentRef, newOptions)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.adiEditFieldPanel.TOGGLE_DEFAULT_CHOICE })
  public async toggleDefaultFieldOption(
    componentRef: ComponentRef,
    newOptions: FieldOption[],
    _biData = {}
  ) {
    return this._editFieldsOptions(componentRef, newOptions)
  }

  @undoable()
  @withBi({ endEvid: EVENTS.PANELS.adiEditFieldPanel.DRAG_CHOICE_COMPLETE })
  public async reorderFieldOptions(
    componentRef: ComponentRef,
    newOptions: FieldOption[],
    _biData = {}
  ) {
    return this._editFieldsOptions(componentRef, newOptions)
  }

  private async _editFieldsOptions(componentRef: ComponentRef, newOptions: FieldOption[]) {
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { options: newOptions },
    })
  }

  @undoable()
  public async updateDefaultOptionValue(componentRef: ComponentRef, newValue) {
    return this.boundEditorSDK.components.data.update({ componentRef, data: { value: newValue } })
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.manageFieldsPanel.DELETE_FIELD })
  @withSync()
  public async removeFieldADI(
    formRef: ComponentRef,
    componentRef: ComponentRef,
    showTitles: boolean,
    _biData = {}
  ) {
    await this.coreApi.removeComponentRef(componentRef)
    return this.coreApi.layout.updateFieldsLayoutADI(formRef, { showTitles })
  }

  private _isElementLayoutInsideOtherElementBoundaries(elementY, { startY, height }) {
    const elementBoundaries = {
      startY: startY,
      endY: startY + height,
    }

    return elementY >= elementBoundaries.startY && elementY <= elementBoundaries.endY
  }

  private async _reLayoutCrucialElement(formComponentRef: ComponentRef, role, positionY) {
    const allLayouts = await this.coreApi.layout.getChildrenLayouts(formComponentRef, CRUCIAL_ROLES)

    const elementLayout = _.find(allLayouts, layout => layout.role === role)

    if (!elementLayout) return

    await this.boundEditorSDK.components.layout.update({
      componentRef: elementLayout.componentRef,
      layout: {
        y: positionY,
      },
    })

    const remainingLayouts = _.filter(allLayouts, layout => layout.role !== role)

    // check if the element we updated isn't overlapping other element layout ( other element inside our element )
    const trespassingLayout = _.find(remainingLayouts, layout =>
      this._isElementLayoutInsideOtherElementBoundaries(layout.y, {
        startY: positionY,
        height: elementLayout.height,
      })
    )

    // check if the element we updated isn't trespassing other element layout ( our element inside other element )
    const overlappingLayout = _.find(
      remainingLayouts,
      layout =>
        this._isElementLayoutInsideOtherElementBoundaries(layout.y, {
          startY: positionY,
          height: elementLayout.height,
        }) ||
        this._isElementLayoutInsideOtherElementBoundaries(positionY, {
          startY: layout.y,
          height: layout.height,
        })
    )

    if (!trespassingLayout && !overlappingLayout) return

    let diffBetweenLayoutsYAxis

    if (trespassingLayout) {
      // calc how much we need to move the trespassing element to get to the bottom of our element ( move element outside our element )
      diffBetweenLayoutsYAxis = positionY + elementLayout.height - trespassingLayout.y
    } else {
      // calc how much we need to move the overlapping element to get to the bottom of our element ( move element away from our element )
      diffBetweenLayoutsYAxis = overlappingLayout.y + overlappingLayout.height - positionY
    }

    // move all other elements with the same delta
    return Promise.all(
      _.map(remainingLayouts, layout => {
        // ignore layouts that exists before our layout
        if (layout.y + layout.height < positionY) {
          return Promise.resolve()
        }

        return this.boundEditorSDK.components.layout.update({
          componentRef: layout.componentRef,
          layout: {
            y: layout.y + diffBetweenLayoutsYAxis + SPACE_BETWEEN_FIELDS,
          },
        })
      })
    )
  }

  public async reLayoutSubmitButton(formComponentRef: ComponentRef) {
    const newSubmitButtonLayout = await this._findNewFieldLocation(formComponentRef)

    return this._reLayoutCrucialElement(
      formComponentRef,
      ROLE_SUBMIT_BUTTON,
      newSubmitButtonLayout.y
    )
  }

  public async reLayoutPreviousButton(stepContainerRef: ComponentRef) {
    const children = await this.coreApi.layout.getChildrenLayouts(stepContainerRef, [
      ROLE_NEXT_BUTTON,
      ROLE_SUBMIT_BUTTON,
    ])
    const roleButton = _.maxBy(children, child => child.y)

    let positionY

    if (roleButton) {
      positionY = roleButton.y + roleButton.height + SPACE_BETWEEN_FIELDS / 2
    } else {
      const newLayout = await this._findNewFieldLocation(stepContainerRef)
      positionY = newLayout.y
    }

    return this._reLayoutCrucialElement(stepContainerRef, ROLE_PREVIOUS_BUTTON, positionY)
  }

  public async reLayoutNextButton(stepComponentRef: ComponentRef) {
    const newLayout = await this._findNewFieldLocation(stepComponentRef)

    return this._reLayoutCrucialElement(stepComponentRef, ROLE_NEXT_BUTTON, newLayout.y)
  }

  public async reLayoutLoginLink(formComponentRef: ComponentRef) {
    const roleSubmitLayout = _.first(
      await this.coreApi.layout.getChildrenLayouts(formComponentRef, ROLE_SUBMIT_BUTTON)
    )

    let positionY

    if (roleSubmitLayout) {
      positionY = roleSubmitLayout.y + roleSubmitLayout.height + SPACE_BETWEEN_FIELDS / 2
    } else {
      const newLayout = await this._findNewFieldLocation(formComponentRef)
      positionY = newLayout.y
    }

    return this._reLayoutCrucialElement(formComponentRef, ROLE_LINK_TO_LOGIN, positionY)
  }

  public async reLayoutHiddenMessage(formComponentRef: ComponentRef, role) {
    const roleSubmitLayout = _.first(
      await this.coreApi.layout.getChildrenLayouts(formComponentRef, ROLE_SUBMIT_BUTTON)
    )

    let positionY

    if (roleSubmitLayout) {
      positionY = roleSubmitLayout.y + roleSubmitLayout.height + SPACE_BETWEEN_FIELDS / 2
    } else {
      const newLayout = await this._findNewFieldLocation(formComponentRef)
      positionY = newLayout.y
    }

    return this._reLayoutCrucialElement(formComponentRef, role, positionY)
  }

  public async reLayoutErrorMessage(formComponentRef: ComponentRef) {
    const childLayouts = await this.coreApi.layout.getChildrenLayouts(formComponentRef, [
      ROLE_SUBMIT_BUTTON,
      ROLE_LINK_TO_LOGIN,
    ])

    const lastLayout = _.maxBy(childLayouts, (field: any) => field.y)

    let positionY

    if (lastLayout) {
      positionY = lastLayout.y + lastLayout.height + SPACE_BETWEEN_FIELDS * 2
    } else {
      const newLayout = await this._findNewFieldLocation(formComponentRef)
      positionY = newLayout.y
    }

    return this._reLayoutCrucialElement(formComponentRef, ROLE_MESSAGE, positionY)
  }

  public async restoreCrucialElement(
    formComponentRef: ComponentRef,
    createElement: (preset: FormPreset, locale, boxLayout) => any,
    parentComponentRef?: ComponentRef
  ) {
    const destComponentRef = parentComponentRef || formComponentRef

    const connectionConfig = await this.coreApi.getComponentConnection(formComponentRef)
    const { controllerRef, config } = connectionConfig
    const boxLayout = await this.boundEditorSDK.components.layout.get({
      componentRef: destComponentRef,
    })
    const locale = await this.boundEditorSDK.info.getLanguage()

    const preset = _.get(config, 'preset')
    const theme = _.get(config, 'theme')

    const fieldPreset = await createElement(preset, locale, boxLayout)
    const styledFieldPreset = await this.coreApi.style.updateFieldPresetTheme(fieldPreset, theme)

    await this.coreApi.addComponentAndConnect(styledFieldPreset, controllerRef, destComponentRef)

    return styledFieldPreset
  }

  private async _isComponentExistsByRole(parentComponentRef: ComponentRef, role: string) {
    const componentRef: ComponentRef = await this.coreApi.findComponentByRole(
      parentComponentRef,
      role
    )
    return !!componentRef
  }

  private async _getContainerToRestoreMessage(formComponentRef: ComponentRef) {
    if (await this.coreApi.isMultiStepForm(formComponentRef)) {
      const { controllerRef } = await this.coreApi.getComponentConnection(formComponentRef)
      return this.coreApi.findConnectedComponent(controllerRef, THANK_YOU_STEP_ROLE)
    }
    return formComponentRef
  }

  @undoable()
  public async restoreHiddenMessage(formComponentRef: ComponentRef, newMessage?: string) {
    if (await this._isComponentExistsByRole(formComponentRef, ROLE_MESSAGE)) {
      return
    }
    return this.coreApi.isResponsive()
      ? this._restoreResponsiveHiddenMessage(formComponentRef, newMessage)
      : this._restoreClassicHiddenMessage(formComponentRef, newMessage)
  }

  private async _restoreClassicHiddenMessage(formComponentRef: ComponentRef, newMessage?: string) {
    const isRegistrationForm = await this.coreApi.isRegistrationForm(formComponentRef)

    const createMessage = async (preset: FormPreset, locale, formLayout) => {
      // TODO: Change this to use getTranslationByPlugin and remove isRegistrationForm statement
      const fallbackSchema = isRegistrationForm
        ? registrationFormMessageStructure
        : hiddenMessageStructure
      const fallbackMessage = isRegistrationForm
        ? translations.t('settings.errorMessage.registrationForm')
        : translations.t('settings.successMessage.default')

      return fetchHiddenMessage(this.ravenInstance)(
        {
          role: ROLE_MESSAGE,
          newMessage: newMessage || fallbackMessage,
          fallbackSchema,
          formLayout,
          preset,
          locale,
        },
        reason => this.coreApi.logFetchPresetsFailed(null, reason)
      )
    }

    const parentComponentRef = await this._getContainerToRestoreMessage(formComponentRef)

    await this.restoreCrucialElement(formComponentRef, createMessage, parentComponentRef)

    if (isRegistrationForm) {
      await this.reLayoutErrorMessage(parentComponentRef)
    } else {
      await this.reLayoutHiddenMessage(parentComponentRef, ROLE_MESSAGE)
    }

    await this.updateFormHeightIfNeeded(parentComponentRef)
  }

  private async _restoreResponsiveHiddenMessage(
    formComponentRef: ComponentRef,
    newMessage?: string
  ) {
    const lastResponsiveLayout = _.last(
      await this.coreApi.layout.getStackChildrenResponsiveLayouts(formComponentRef)
    )
    const orderInStack: number = lastResponsiveLayout
      ? getOrderFromResponsiveLayout(lastResponsiveLayout.layoutResponsive) + 1
      : 1
    const createMessage = (preset: FormPreset, locale, formLayout) =>
      fetchHiddenMessage(this.ravenInstance)(
        {
          role: ROLE_MESSAGE,
          newMessage: newMessage || translations.t('settings.successMessage.default'),
          fallbackSchema: hiddenMessageStructure,
          formLayout,
          preset,
          locale,
          orderInStack,
        },
        reason => this.coreApi.logFetchPresetsFailed(null, reason)
      )

    return this.restoreCrucialElement(formComponentRef, createMessage)
  }

  public async restoreDownloadDocumentMessage(formComponentRef: ComponentRef, newMessage) {
    if (await this._isComponentExistsByRole(formComponentRef, ROLE_DOWNLOAD_MESSAGE)) {
      return
    }

    const createMessage = async (preset: FormPreset, locale, formLayout) => {
      newMessage = `<span style="text-decoration: underline">${newMessage}</span>`

      return fetchHiddenMessage(this.ravenInstance)(
        {
          fallbackSchema: hiddenMessageStructure,
          role: ROLE_DOWNLOAD_MESSAGE,
          newMessage,
          formLayout,
          preset,
          locale,
        },
        reason => this.coreApi.logFetchPresetsFailed(null, reason)
      )
    }

    const parentComponentRef = await this._getContainerToRestoreMessage(formComponentRef)

    await this.restoreCrucialElement(parentComponentRef, createMessage)
    await this.reLayoutHiddenMessage(parentComponentRef, ROLE_DOWNLOAD_MESSAGE)
    await this.updateFormHeightIfNeeded(parentComponentRef)
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS[PanelName.FORM_SETTINGS].RESTORE_CRUCIAL_ELEMENTS })
  public async restoreSubmitButton(componentRef: ComponentRef, _biData = {}) {
    if (await this._isComponentExistsByRole(componentRef, ROLE_SUBMIT_BUTTON)) {
      return
    }

    return this.coreApi.isResponsive()
      ? this._restoreResponsiveSubmitButton(componentRef)
      : this._restoreClassicSubmitButton(componentRef)
  }

  private async _restoreClassicSubmitButton(componentRef: ComponentRef) {
    let containerRef = componentRef

    if (await this.coreApi.isMultiStepForm(componentRef)) {
      const stepsData = await this.coreApi.steps.getSteps(componentRef)
      const stepsWithoutThankYouStep = _.filter(
        stepsData,
        step => step.role !== THANK_YOU_STEP_ROLE
      )
      containerRef = _.last(stepsWithoutThankYouStep).componentRef
    }

    // TODO: Extract registration form scope when working on plugin system
    const isRegistrationForm = await this.coreApi.isRegistrationForm(componentRef)
    const label = translations.t(`preset.${isRegistrationForm ? 'signup' : 'submit'}ButtonLabel`)
    const fallbackSchema = isRegistrationForm ? signupButtonStructure : submitButtonStructure

    const createButton = async (preset: FormPreset, locale, _boxLayout) =>
      fetchSubmitButtonSchema(this.ravenInstance)(
        { label, preset, locale, fallbackSchema },
        reason => this.coreApi.logFetchPresetsFailed(null, reason)
      )

    await this.restoreCrucialElement(componentRef, createButton, containerRef)
    await this.reLayoutSubmitButton(containerRef)
    await this.updateFormHeightIfNeeded(containerRef)
  }

  private async _restoreResponsiveSubmitButton(componentRef: ComponentRef) {
    const stackLayouts = await this.coreApi.layout.getStackChildrenResponsiveLayouts(componentRef)
    const messageLayout = _.find(stackLayouts, { role: ROLE_MESSAGE })
    const lastItemLayout = _.last(stackLayouts)
    const orderInStack = messageLayout
      ? getOrderFromResponsiveLayout(messageLayout.layoutResponsive) - 1
      : lastItemLayout
      ? getOrderFromResponsiveLayout(lastItemLayout.layoutResponsive) + 1
      : 1
    const createButton = async (preset: FormPreset, locale, _boxLayout) =>
      fetchSubmitButtonSchema(this.ravenInstance)(
        {
          label: translations.t(`preset.submitButtonLabel`),
          preset,
          locale,
          fallbackSchema: submitButtonStructure,
          orderInStack,
        },
        reason => this.coreApi.logFetchPresetsFailed(null, reason)
      )

    const submitButtonComponentRef: ComponentRef = await this.restoreCrucialElement(
      componentRef,
      createButton,
      componentRef
    )
    await this._fixFieldsOrderAfterFieldAdded(componentRef, submitButtonComponentRef, orderInStack)
  }

  public async updateFormHeightIfNeeded(componentRef: ComponentRef) {
    const childLayouts = await this.coreApi.layout.getChildrenLayouts(componentRef, null, true)
    const lastLayout: any = _.maxBy(childLayouts, (field: any) => field.y)
    const formLayout = await this.boundEditorSDK.components.layout.get({ componentRef })

    const formBottomY = _.get(formLayout, 'y', 0) + _.get(formLayout, 'height', 0)
    const lastLayoutBottomY = _.get(lastLayout, 'y', 0) + _.get(lastLayout, 'height', 0)

    if (formBottomY - SPACE_BETWEEN_FIELDS > lastLayoutBottomY) return

    const extraHeight = lastLayoutBottomY - formBottomY + SPACE_BETWEEN_FIELDS

    return this.coreApi.addHeightToContainers(componentRef, extraHeight)
  }

  public async restoreLoginLink(componentRef: ComponentRef) {
    const label = translations.t(`fieldTypes.regForm_linkToLoginDialog.text`)

    const createLoginLink = async (preset: FormPreset, locale, _boxLayout) =>
      fetchLoginLinkSchema(this.ravenInstance)(
        { label, preset, locale, fallbackSchema: registrationLoginLinkStructure },
        reason => this.coreApi.logFetchPresetsFailed(null, reason)
      )

    await this.restoreCrucialElement(componentRef, createLoginLink)
    await this.reLayoutLoginLink(componentRef)
    await this.updateFormHeightIfNeeded(componentRef)
  }
}

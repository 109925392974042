import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import { GeneralSingleCheckbox } from './general-single-checkbox'
import { iconNames } from '../../icons/types'
import { FormPlugin } from '../../../../../../constants/plugins'
import { FieldRenderConfigType } from '../../constants'

export class GeneralSubscribe extends GeneralSingleCheckbox {
  public get fieldType() {
    return FormsFieldPreset.GENERAL_SUBSCRIBE
  }

  protected get icon() {
    return iconNames.subscribe
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_SUBSCRIBE
  }

  protected get data(): any {
    return {
      label: this.translate(`preset.${this.fieldType}Label`),
    }
  }

  protected get props() {
    return {
      required: false,
    }
  }

  protected get layout() {
    return {
      width: 290,
    }
  }

  public get allowOnlyOnce() {
    return true
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        crmSync: FieldRenderConfigType.REMOVE,
      }
    }
  }
}

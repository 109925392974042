import translations from '../../../utils/translations'
import { CRM_TAGS, crmTypesTags, CUSTOM_FIELD } from '../../../constants/crm-types-tags'
import * as _ from 'lodash'
import { ALL_FIELDS_DATA } from '../preset/fields/field-types-data'
import { createSuffixedName } from '../../../utils/utils'
import { ComponentConfig, FormField } from '../../../constants/api-types'
import { COMPONENT_TYPES } from '../../../constants/component-types'
import { isCrmTypeAllowedOnce } from '../../../panels/contact-sync-panel/utils'

export const getFieldsLeft = (fieldsInFormLength: number, limit: number) =>
  limit > -1 ? limit - fieldsInFormLength : Infinity

export const getDuplicatedFieldConfig = (
  fields: Partial<FormField>[],
  config: ComponentConfig
): ComponentConfig => {
  const duplicatedFieldName = translations.t('manageFields.duplicatedFormName', {
    name: config.crmLabel,
  })
  const newCrmLabel = createSuffixedName(
    _.map(fields, (field: Partial<FormField>) => field.crmLabel),
    duplicatedFieldName
  )
  const isCrmTypeTag = !!crmTypesTags[config.crmType]

  const fieldData = ALL_FIELDS_DATA[config.fieldType]
  const fieldType = fieldData.metadata.allowOnlyOnce
    ? fieldData.base.metadata.fieldType
    : fieldData.metadata.fieldType

  return _.assign({}, config, {
    collectionFieldType: ALL_FIELDS_DATA[config.fieldType].properties.collectionFieldType,
    crmLabel: newCrmLabel,
    crmTag: isCrmTypeTag ? CRM_TAGS.OTHER : config.crmTag,
    crmType: isCrmTypeTag ? config.crmType : CUSTOM_FIELD,
    fieldType,
    customFieldId: undefined,
    customFieldName: undefined,
    collectionFieldKey: createSuffixedName(
      _.map(fields, 'collectionFieldKey'),
      _.camelCase(newCrmLabel),
      ''
    ),
  })
}

export const getDefaultLabel = ({ titleText, buttonLabel, label, placeholder, fieldType }) => {
  const componentType = ALL_FIELDS_DATA[fieldType].properties.componentType
  const fieldPlaceholder = _.isString(placeholder) ? placeholder : _.get(placeholder, 'text')

  switch (componentType) {
    case COMPONENT_TYPES.FILE_UPLOADER:
      return label || buttonLabel
    case COMPONENT_TYPES.SIGNATURE_INPUT:
    case COMPONENT_TYPES.RATING:
      return titleText
    case COMPONENT_TYPES.CHECKBOX_GROUP:
      return label
    default:
      return label || fieldPlaceholder
  }
}

const MAXIMUM_FIELD_NAME_LENGTH = 25

export const getDefaultFieldName = ({
  fieldStructure,
  fieldsOnStage,
}: {
  fieldStructure: any
  fieldsOnStage?: FormField[]
}): string => {
  const data = fieldStructure.data.data
  const { placeholder, titleText, buttonLabel, label, placeholderLabel } = data

  const { crmLabel, fieldType } = fieldStructure.connectionConfig
  const fieldNames = _.map(fieldsOnStage, (field: FormField) => field.crmLabel)

  const defaultPlaceholder: string = placeholder || placeholderLabel

  const defaultLabel: string = getDefaultLabel({
    titleText,
    buttonLabel,
    label,
    placeholder: defaultPlaceholder,
    fieldType,
  })

  const defaultFieldName = (defaultLabel || crmLabel).slice(0, MAXIMUM_FIELD_NAME_LENGTH)

  return createSuffixedName(fieldNames, defaultFieldName, ' ')
}

export const isFieldAllowedOnceByCrmType = crmType => crmType && isCrmTypeAllowedOnce(crmType)

export const isFieldAllowedOnceByFieldType = fieldType =>
  fieldType && !!ALL_FIELDS_DATA[fieldType].metadata.allowOnlyOnce

export const isFieldAllowedOnlyOnce = (fieldType, crmType) =>
  isFieldAllowedOnceByFieldType(fieldType) || isFieldAllowedOnceByCrmType(crmType)

import { FIELDS } from '../../../../../../constants/roles'
import { RegistrationFieldPreset } from '../../../../../../constants/field-types'
import { CheckboxAgreeTerms } from './checkbox-agree-terms'

export class CodeOfConduct extends CheckboxAgreeTerms {
  public get fieldType() {
    return RegistrationFieldPreset.REGISTRATION_FORM_CODE_OF_CONDUCT
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_REGISTRATION_FORM_CODE_OF_CONDUCT
  }
}

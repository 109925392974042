import { CRM_TYPES, CRM_TAGS } from '../../../constants/crm-types-tags'
import * as _ from 'lodash'
import { isCrmTypeAllowedOnce } from '../utils'

export const mainCrmTypes = _.values(CRM_TYPES)

export const mainCrmTypesSyncData = mainCrmTypes.map(crmType => ({
  crmType,
  crmTag: isCrmTypeAllowedOnce(crmType) ? CRM_TAGS.MAIN : CRM_TAGS.OTHER,
  customFieldId: undefined,
  customFieldName: undefined,
}))

import { COMPONENT_TYPES } from '../../../../constants/component-types'

export const FIELDS_THAT_SUPPORT_SHOWING_LABEL = [
  COMPONENT_TYPES.RADIO_GROUP,
  COMPONENT_TYPES.CHECKBOX_GROUP,
  COMPONENT_TYPES.TEXT_INPUT,
  COMPONENT_TYPES.TEXT_AREA_INPUT,
  COMPONENT_TYPES.DATE_PICKER,
  COMPONENT_TYPES.COMBOBOX,
  COMPONENT_TYPES.FILE_UPLOADER,
  COMPONENT_TYPES.TIME_PICKER,
]

import { FormsFieldPreset, FieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { DEFAULT_PLACEHOLDER } from '../constants'
import { CUSTOM_FIELD } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'

export class GeneralText extends IGeneralField {
  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_TEXT
  }

  protected get icon() {
    return iconNames.shortText
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get componentType() {
    return COMPONENT_TYPES.TEXT_INPUT
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_TEXT
  }

  public get crmType() {
    return CUSTOM_FIELD
  }

  protected get data(): any {
    return {
      textType: 'text',
      placeholder: this.translate(DEFAULT_PLACEHOLDER),
    }
  }

  public get base() {
    return GeneralText.prototype
  }
}

import { FIELDS } from '../../../../constants/roles'

type RoleKeys = keyof typeof FIELDS
type RoleValues = typeof FIELDS[RoleKeys]

const textInputFieldOverride = {
  panelSectionsDefinition: {
    inputType: 'hidden',
    value: 'hidden',
    readOnly: 'hidden',
    maxLength: 'hidden',
    max: 'hidden',
    min: 'hidden',
    patternValidation: 'hidden',
  },
}

const textAreaFieldOverride = {
  panelSectionsDefinition: {
    value: 'hidden',
    readOnly: 'hidden',
    maxLength: 'hidden',
  },
}

const checkboxFieldOverride = {
  panelSectionsDefinition: {
    value: 'hidden',
  },
}

const joinCommunityFieldOverride = {
  panelSectionsDefinition: {
    value: 'hidden',
    link: 'hidden',
  },
}

const datePickerOverride = {
  panelSectionsDefinition: {
    readOnly: 'hidden',
  },
}

const timePickerOverride = {
  panelSectionsDefinition: {
    readOnly: 'hidden',
  }
}
export const nativePanelOverrides: {[value in RoleValues]: { panelSectionsDefinition?: object }} = {
  [FIELDS.ROLE_FIELD_TEXT]: textInputFieldOverride,
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL]: textInputFieldOverride,
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD]: textInputFieldOverride,
  [FIELDS.ROLE_FIELD_TEXT_AREA]: textAreaFieldOverride,
  [FIELDS.ROLE_FIELD_CHECKBOX]: checkboxFieldOverride,
  [FIELDS.ROLE_FIELD_SUBSCRIBE]: checkboxFieldOverride,
  [FIELDS.ROLE_FIELD_AGREE_TERMS]: checkboxFieldOverride,
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_AGREE_TERMS]: checkboxFieldOverride,
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CODE_OF_CONDUCT]: checkboxFieldOverride,
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_PRIVACY_POLICY]: checkboxFieldOverride,
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY]: joinCommunityFieldOverride,
  [FIELDS.ROLE_FIELD_DATE]: datePickerOverride,
  [FIELDS.ROLE_FIELD_TIME_PICKER]: timePickerOverride,
}

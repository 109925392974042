import { AdiFieldPreset } from '../../../../../../constants/field-types'
import {
  GeneralDropdown,
} from '../../general-fields/definitions/general-dropdown'

export class AdiGeneralDropdown extends GeneralDropdown {
  public get fieldType() {
    return AdiFieldPreset.ADI_GENERAL_DROPDOWN
  }

  protected get data() {
    return {
      placeholder: {
        value: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.placeholder`),
        text: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.placeholder`),
      },
      label: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.label`),
    }
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${this.base.metadata.fieldType}FieldEditPanel.title`
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${this.base.metadata.fieldType}`)
  }
}

import * as _ from 'lodash'
import { RECOMMENDED_FIELDS } from './recommended-fields'
import { GENERAL_FIELDS } from './general-fields'
import { REGISTRATION_FIELDS } from './registration-form-fields'
import { FieldCollectionType } from '../../../../constants/wixcode'
import { COMPONENT_TYPES } from '../../../../constants/component-types'
import { FieldPreset } from '../../../../constants/field-types'
import { FieldRenderConfigType } from './constants'
import { FormPlugin } from '../../../../constants/plugins'
import { ADI_FIELDS } from './adi-form-fields'
import { ResponsiveLayout } from '@wix/platform-editor-sdk'
import { IGeneralField } from './general-field'

export interface FieldExtraData {
  role: string
  connectionConfig?: {
    crmType: string
    crmLabel?: string
    crmTag?: string
    customFieldId?: string
    label?: string
  }
  props?: any
  data?: any
  layout?: any
  layoutResponsive?: ResponsiveLayout
}

export interface FieldProperties {
  collectionFieldType?: FieldCollectionType | false
  componentType: COMPONENT_TYPES
  extraData: FieldExtraData
}

export interface FieldRenderConfigStructure {
  isMandatory?: boolean
  crmSync?: FieldRenderConfigType
  connectPanelRegistrationFooter?: FieldRenderConfigType
  required?: FieldRenderConfigType
  internalName?: FieldRenderConfigType
  checkedByDefault?: FieldRenderConfigType
  isEditable?: boolean
}

export type FieldRenderConfig = { [key in FormPlugin]?: FieldRenderConfigStructure }

// TODO: Merge this with default form builder config render and merge unused flows (AKA === required.ENABLED/REMOVE? why both?)
const DEFAULT_FIELD_RENDER_CONFIG: FieldRenderConfigStructure = {
  isEditable: true,
  isMandatory: false,
  crmSync: FieldRenderConfigType.ENABLED,
  connectPanelRegistrationFooter: FieldRenderConfigType.REMOVE,
  required: FieldRenderConfigType.ENABLED,
  internalName: FieldRenderConfigType.ENABLED,
  checkedByDefault: FieldRenderConfigType.ENABLED,
}

export type PluginsFieldExtraData = { [key in FormPlugin]?: Partial<FieldExtraData> }

export const ALL_FIELDS_DATA: { [key in FieldPreset]?: IGeneralField } = _.reduce(
  [...RECOMMENDED_FIELDS, ...GENERAL_FIELDS, ...REGISTRATION_FIELDS, ...ADI_FIELDS],
  (acc, field: IGeneralField) => {
    acc[field.fieldType] = field
    return acc
  },
  {}
)

export const getFieldRenderConfigFields = ( // TODO: Can we remove this function?
  plugins: FormPlugin[],
  fieldType: FieldPreset,
  defaults = {}
): FieldRenderConfigStructure => {
  const config = ALL_FIELDS_DATA[fieldType].metadata.renderConfig
  const plugin = _.first(plugins) // current implementation support only 1 plugin per preset, change this when we will have more than 1
  let basePluginConfig = {}

  if (config && config[FormPlugin.FORM_BUILDER]) {
    basePluginConfig = config[FormPlugin.FORM_BUILDER]
  }

  if (config && plugin && config[plugin]) {
    return _.merge({}, DEFAULT_FIELD_RENDER_CONFIG, basePluginConfig, config[plugin])
  } else {
    return _.merge({}, DEFAULT_FIELD_RENDER_CONFIG, basePluginConfig, defaults)
  }
}

export const getFieldPluginsExtraData = ({
  fieldType,
  plugins,
}: {
  fieldType: FieldPreset
  plugins: FormPlugin[]
}): Partial<FieldExtraData> => {
  const pluginsExtraData = ALL_FIELDS_DATA[fieldType].metadata.pluginsExtraData

  let extraData = {}

  if (!pluginsExtraData) {
    return extraData
  }

  _.forEach(plugins, plugin => {
    const pluginExtraData = _.get(pluginsExtraData, plugin)

    if (pluginExtraData) {
      _.merge(extraData, pluginExtraData)
    }
  })

  return extraData
}

export const allowCollectionSync = (fieldType: FieldPreset): boolean => {
  return !!!ALL_FIELDS_DATA[fieldType].metadata.disableCollectionSync
}

import { FormsFieldPreset, AdiFieldPreset } from '../../../../../../constants/field-types'
import { Address } from '../../recommended-fields/definitions/address'

export class AdiAddress extends Address {
  public get fieldType() {
    return AdiFieldPreset.ADI_ADDRESS
  }

  protected get data() {
    return {
      placeholder: this.translate(`adi.fieldTypes.${FormsFieldPreset.ADDRESS}.placeholder`),
      label: this.translate(`adi.fieldTypes.${FormsFieldPreset.ADDRESS}.label`),
    }
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.ADDRESS}`)
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${FormsFieldPreset.ADDRESS}FieldEditPanel.title`
  }
}

import { FormsFieldPreset, AdiFieldPreset } from '../../../../../../constants/field-types'
import { Phone } from '../../recommended-fields/definitions/phone'

export class AdiPhone extends Phone {
  public get fieldType() {
    return AdiFieldPreset.ADI_PHONE
  }

  protected get data() {
    return {
      textType: 'tel',
      placeholder: this.translate(`adi.fieldTypes.${FormsFieldPreset.PHONE}.placeholder`),
      label: this.translate(`adi.fieldTypes.${FormsFieldPreset.PHONE}.label`),
    }
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.PHONE}`)
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${FormsFieldPreset.PHONE}FieldEditPanel.title`
  }
}

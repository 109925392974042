import { FormsFieldPreset, FieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'

export const makeGeneralDropdownOption = () => ({
  type: 'SelectOption',
  metaData: {
    isPreset: false,
    schemaVersion: '1.0',
    isHidden: false,
  },
  value: '',
  text: '',
  description: '',
  disabled: false,
})

export class GeneralDropdown extends IGeneralField {
  protected get icon() {
    return iconNames.dropdown
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_DROP_DOWN
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  protected get componentType() {
    return COMPONENT_TYPES.COMBOBOX
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_SELECT
  }

  protected get data(): any {
    return {
      placeholder: {
        value: this.translate(`preset.${this.fieldType}PlaceholderValue`),
        text: this.translate(`preset.${this.fieldType}PlaceholderText`),
      },
      options: [
        {
          ...makeGeneralDropdownOption(),
          value: this.translate(`preset.${this.fieldType}FirstOptionsValue`),
          text: this.translate(`preset.${this.fieldType}FirstOptionsText`),
          description: this.translate(`preset.${this.fieldType}FirstOptionsText`),
        },
        {
          ...makeGeneralDropdownOption(),
          value: this.translate(`preset.${this.fieldType}SecondOptionsValue`),
          text: this.translate(`preset.${this.fieldType}SecondOptionsText`),
          description: this.translate(`preset.${this.fieldType}SecondOptionsText`),
        },
        {
          ...makeGeneralDropdownOption(),
          value: this.translate(`preset.${this.fieldType}ThirdOptionsValue`),
          text: this.translate(`preset.${this.fieldType}ThirdOptionsText`),
          description: this.translate(`preset.${this.fieldType}ThirdOptionsText`),
        },
        {
          ...makeGeneralDropdownOption(),
          value: this.translate(`preset.${this.fieldType}ForthOptionsValue`),
          text: this.translate(`preset.${this.fieldType}ForthOptionsText`),
          description: this.translate(`preset.${this.fieldType}ForthOptionsText`),
        },
      ],
    }
  }

  public get base() {
    return GeneralDropdown.prototype
  }
}

import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { GeneralUrl } from '../../general-fields/definitions/general-url'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'

export class Website extends GeneralUrl {
  public get customFields() {
    if (this.isContactsListTabExperiment()) {
      return this.base.customFields
    }
    return []
  }

  public get fieldType() {
    return FormsFieldPreset.WEBSITE
  }

  public get crmType() {
    return CRM_TYPES.WEBSITE
  }

  public get crmTag() {
    return CRM_TAGS.OTHER
  }

  protected get data() {
    return {
      placeholder: this.translate(`fieldTypes.${this.fieldType}`),
    }
  }
}

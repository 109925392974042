import { FormsFieldPreset } from '../../../../../../constants/field-types'
import { GeneralText } from '../../general-fields/definitions/general-text'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

export class Position extends GeneralText {
  public get customFields() {
    if (this.isContactsListTabExperiment()) {
      return this.base.customFields
    }
    return []
  }

  public get fieldType() {
    return FormsFieldPreset.POSITION
  }

  protected get icon() {
    return iconNames.position
  }

  public get crmType() {
    return CRM_TYPES.POSITION
  }

  protected get data() {
    return {
      placeholder: this.translate(`fieldTypes.${this.fieldType}`),
    }
  }
}

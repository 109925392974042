import { FormsFieldPreset, FieldPreset } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'
import { FieldRenderConfigType } from '../../constants'
import { FormPlugin } from '../../../../../../constants/plugins'

export class GeneralTimePicker extends IGeneralField {
  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_TIME_PICKER
  }

  protected get icon() {
    return iconNames.time
  }

  public get collectionFieldType() {
    return FieldCollectionType.TIME
  }

  protected get componentType() {
    return COMPONENT_TYPES.TIME_PICKER
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_TIME_PICKER
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        crmSync: FieldRenderConfigType.REMOVE,
      },
    }
  }

  public get base() {
    return GeneralTimePicker.prototype
  }
}

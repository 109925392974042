import { FormsFieldPreset, RegistrationFieldPreset } from '../../../constants/field-types'

export const RECOMMENDED_FIELDS = [
  FormsFieldPreset.FIRST_NAME,
  FormsFieldPreset.LAST_NAME,
  FormsFieldPreset.COMPANY,
  FormsFieldPreset.POSITION,
  FormsFieldPreset.EMAIL,
  FormsFieldPreset.ADDRESS,
  FormsFieldPreset.PHONE,
  FormsFieldPreset.BIRTHDAY,
  FormsFieldPreset.ANNIVERSARY,
  FormsFieldPreset.WEBSITE,
  FormsFieldPreset.AGREE_TERMS,
]

export const REGISTRATION_FORM_RECOMMENDED_FIELDS = [
  FormsFieldPreset.FIRST_NAME,
  FormsFieldPreset.LAST_NAME,
  FormsFieldPreset.EMAIL,
  FormsFieldPreset.PHONE,
]

export enum CATEGORIES {
  none = 'none',
  recommended = 'recommended',
  text = 'text',
  number = 'number',
  selection = 'selection',
  date = 'date',
  upload = 'upload',
  subscription = 'subscription',
  registration = 'registration',
}

export enum DEFAULT_CATEGORIES {
  none = 'none',
  recommended = 'recommended',
  text = 'text',
  number = 'number',
  selection = 'selection',
  date = 'date',
  upload = 'upload',
  subscription = 'subscription',
  recaptcha = 'recaptcha',
  signature = 'signature',
}

export const REGISTRATION_FORM_CATEGORY = 'registration'

export interface FieldConfig {
  id: FormsFieldPreset
  category: CATEGORIES | DEFAULT_CATEGORIES
  isPremium?: boolean
  dependsOn?: string[]
  hideTranslationPostfix?: boolean
  showTooltip?: boolean
  subCategory?: CATEGORIES | DEFAULT_CATEGORIES
}

export const CUSTOM_FIELDS: FieldConfig[] = [
  // TODO: move to field definition
  { id: FormsFieldPreset.GENERAL_TEXT, category: DEFAULT_CATEGORIES.text },
  { id: FormsFieldPreset.GENERAL_TEXT_BOX, category: DEFAULT_CATEGORIES.text },
  { id: FormsFieldPreset.GENERAL_URL, category: DEFAULT_CATEGORIES.text },
  { id: FormsFieldPreset.GENERAL_NUMBER, category: DEFAULT_CATEGORIES.number },
  { id: FormsFieldPreset.GENERAL_RADIO_BUTTON, category: DEFAULT_CATEGORIES.selection },
  { id: FormsFieldPreset.GENERAL_SINGLE_CHECKBOX, category: DEFAULT_CATEGORIES.selection },
  {
    id: FormsFieldPreset.GENERAL_RECAPTCHA,
    category: DEFAULT_CATEGORIES.recaptcha,
    hideTranslationPostfix: true,
  },
  {
    id: FormsFieldPreset.GENERAL_SUBSCRIBE,
    category: DEFAULT_CATEGORIES.subscription,
    dependsOn: [FormsFieldPreset.EMAIL, RegistrationFieldPreset.REGISTRATION_FORM_LOGIN_EMAIL],
    hideTranslationPostfix: true,
    showTooltip: true,
    subCategory: DEFAULT_CATEGORIES.selection,
  },
  { id: FormsFieldPreset.GENERAL_CHECKBOX, category: DEFAULT_CATEGORIES.selection },
  { id: FormsFieldPreset.GENERAL_DROP_DOWN, category: DEFAULT_CATEGORIES.selection },
  {
    id: FormsFieldPreset.GENERAL_UPLOAD_BUTTON,
    category: DEFAULT_CATEGORIES.upload,
    isPremium: true,
  },
  { id: FormsFieldPreset.GENERAL_DATE_PICKER, category: DEFAULT_CATEGORIES.date },
  { id: FormsFieldPreset.GENERAL_TIME_PICKER, category: DEFAULT_CATEGORIES.date },
  { id: FormsFieldPreset.GENERAL_RATING, category: DEFAULT_CATEGORIES.selection },
  {
    id: FormsFieldPreset.GENERAL_SIGNATURE,
    category: DEFAULT_CATEGORIES.signature,
    isPremium: true,
  },
]

export const MENU_ACTIONS_OVERRIDE = {
  toggleDefault: {
    enable: false,
  },
  duplicate: {
    enable: false,
  },
  toggleEditLabel: {
    enable: false,
  },
  toggleEditValue: {
    enable: false,
  },
  delete: {
    enable: false,
  },
}

export const FIELD_GROUPS = {
  CUSTOM: 'custom',
  CRM: 'crm fields',
  RECOMMENDED: 'recommended',
  REGISTRATION: 'registration',
}

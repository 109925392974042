import { AdiFieldPreset } from '../../../../../../constants/field-types'
import { GeneralUrl } from '../../general-fields/definitions/general-url'

export class AdiUrl extends GeneralUrl {
  public get fieldType() {
    return AdiFieldPreset.ADI_URL
  }

  protected get data() {
    return {
      placeholder: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.placeholder`),
      label: this.translate(`adi.fieldTypes.${this.base.metadata.fieldType}.label`),
    }
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${this.base.metadata.fieldType}`)
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${this.base.metadata.fieldType}FieldEditPanel.title`
  }
}

import { FormsFieldPreset, AdiFieldPreset } from '../../../../../../constants/field-types'
import { LastName } from '../../recommended-fields/definitions/last-name'

export class AdiLastName extends LastName {
  public get fieldType() {
    return AdiFieldPreset.ADI_LAST_NAME
  }

  protected get data() {
    return {
      placeholder: this.translate(`adi.fieldTypes.${FormsFieldPreset.LAST_NAME}.placeholder`),
      label: this.translate(`adi.fieldTypes.${FormsFieldPreset.LAST_NAME}.label`),
    }
  }

  protected get props() {
    return {
      required: true,
    }
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.LAST_NAME}`)
  }

  public get allowOnlyOnce() {
    return true
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${FormsFieldPreset.LAST_NAME}FieldEditPanel.title`
  }
}

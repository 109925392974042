import { FormsFieldPreset, AdiFieldPreset } from '../../../../../../constants/field-types'
import { Email, EMAIL_REGEX } from '../../recommended-fields/definitions/email'

export class AdiEmail extends Email {
  public get fieldType() {
    return AdiFieldPreset.ADI_EMAIL
  }

  protected get data() {
    return {
      placeholder: this.translate(`adi.fieldTypes.${FormsFieldPreset.EMAIL}.placeholder`),
      label: this.translate(`adi.fieldTypes.${FormsFieldPreset.EMAIL}.label`),
      textType: 'email',
      pattern: EMAIL_REGEX,
    }
  }

  protected get props() {
    return {
      required: true,
    }
  }

  protected get ADI_titleTranslationKey() {
    return `adi.${FormsFieldPreset.EMAIL}FieldEditPanel.title`
  }

  protected get crmLabel() {
    return this.translate(`fieldTypes.${FormsFieldPreset.EMAIL}`)
  }
}

import { undoable } from '../decorators'

const HIDE_GET_SUBSCRIBERS_PANEL_KEY = 'dontShowGetSubscribersFirstTimePanel'

export default class SettingsApi {
  private boundEditorSDK: any

  constructor(boundEditorSDK) {
    this.boundEditorSDK = boundEditorSDK
  }

  @undoable()
  public async updateGetSubscribersHideFirstTimePanel(selected: boolean): Promise<void> {
    return this.boundEditorSDK.editor.userPreferences.site.set({ [HIDE_GET_SUBSCRIBERS_PANEL_KEY]: selected })
  }

  public async getSubscribersHideFirstTimePanel(): Promise<boolean> {
    const preferences = await this.boundEditorSDK.editor.userPreferences.site.get([HIDE_GET_SUBSCRIBERS_PANEL_KEY])
    return preferences[HIDE_GET_SUBSCRIBERS_PANEL_KEY]
  }
}

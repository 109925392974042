import { FormsFieldPreset, customTypes, FieldPreset  } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../../../../../constants/component-types'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'

export const URL_REGEX = '^((https?|ftp)://)?[^\\s/$.?#]*\\.[^\\s]*$'

export class GeneralUrl extends IGeneralField {
  public get customFields() {
    return [customTypes.URL]
  }

  protected get icon() {
    return iconNames.website
  }

  public get collectionFieldType() {
    return FieldCollectionType.URL
  }

  protected get componentType() {
    return COMPONENT_TYPES.TEXT_INPUT
  }

  protected get role() {
    return FIELDS.ROLE_FIELD_TEXT
  }

  protected get data(): any {
    return {
      textType: 'text',
      pattern: URL_REGEX,
      placeholder: this.translate(`fieldTypes.${this.fieldType}.placeholderText`),
    }
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_URL
  }

  public get base() {
    return GeneralUrl.prototype
  }
}

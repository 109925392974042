import { FieldPreset } from './field-types'
import { SuccessActionTypes } from './success-settings'
import { FormPreset, FormPresetType } from './form-types'
import { FormPlugin } from './plugins'
import { Theme } from './form-style'
import { COMPONENT_TYPES } from './component-types'
import { ResponsiveLayout } from '@wix/platform-editor-sdk'

export enum FileType {
  IMAGE = 'Image',
  DOCUMNET = 'Document',
}

export enum OptionType {
  SELECT_OPTION = 'SelectOption',
  RADIO_BUTTON = 'RadioButton',
  CHECKBOX_GROUP = 'CheckboxInput',
}

export interface RadioOption {
  label: string
  value: any
  type: OptionType.RADIO_BUTTON
}

export interface CheckboxOption {
  label: string
  value: any
  checked: boolean
  type: OptionType.CHECKBOX_GROUP
}

export interface DropdownOption {
  value: any
  text: string
  description: string
  disabled: boolean
  type: OptionType.SELECT_OPTION
}

export type FieldOption = RadioOption | DropdownOption | CheckboxOption

export enum PROP_TEXT_ALIGNMENT {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum PROP_ALIGNMENT {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum PROP_DIRECTION {
  RTL = 'rtl',
  LTR = 'ltr',
}

export enum PROP_LAYOUT {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum PROP_LABEL_POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
  SIDE = 'side',
}

export interface FormField {
  componentRef: ComponentRef
  parentComponentRef?: ComponentRef
  crmLabel?: string
  required: boolean
  crmType?: string
  crmTag?: string
  fieldType: FieldPreset
  customFieldId?: string
  customFieldName?: string
  collectionFieldKey?: string
  collectionFieldType?: string
  checked?: boolean
  role: string
  label?: string
  placeholder?: FieldPlaceholder
  showLabel?: boolean
  buttonLabel?: string
  titleText?: string
  clearButtonText?: string
  x?: number
  y?: number
  height?: number
  width?: number
  dateFormat?: string
  options?: FieldOption[]
  defaultValue?: string
  filesType?: FileType
  showPlaceholder?: boolean
  loading?: boolean
  componentType: COMPONENT_TYPES
  id?: string
  textAlignment?: PROP_TEXT_ALIGNMENT
  textPadding?: number
  labelPadding?: number
  labelMargin?: number
  alignment?: PROP_ALIGNMENT
  buttonsMargin?: number
  spacing?: number
  buttonSize?: number
  layout?: PROP_LAYOUT
  layoutResponsive?: ResponsiveLayout
  labelAlignment?: PROP_TEXT_ALIGNMENT
  shapeSpacing?: number
  shapeSize?: number
  labelPosition?: PROP_LABEL_POSITION
  direction?: PROP_DIRECTION
  filesAlignment?: PROP_TEXT_ALIGNMENT
  buttonAlignment?: PROP_DIRECTION
  inputHeight?: number
  titlePaddingStart?: number
  titleMarginBottom?: number
}

export type formComponent = {
  presetKey?: string
  rawComp: any
  theme?: Theme
  newMessage?: string
}

export type Plugin = {
  id: FormPlugin
  payload?: any
}

export type ComponentConfig = {
  formName?: string
  preset?: FormPreset
  fieldType?: FieldPreset
  plugins?: Plugin[]
  collectionId?: string
  successActionType?: SuccessActionTypes
  labels?: string[]
  successLinkValue?: any
  theme?: Theme
  emailId?: string
  secondEmailId?: string
  formLabelId?: string
  collectionFieldKey?: string
  crmLabel?: string
  crmType?: string
  crmTag?: string
  customFieldId?: string
  collectionFieldType?: string
  label?: string
  product?: ProductData
  errorMessage?: string
  secondsToResetForm?: number
  presetType?: FormPresetType
  msid?: string
  title?: string
  isDummyForm?: boolean
}

export type ComponentConnection = {
  role: string
  config: ComponentConfig
  controllerRef: ComponentRef
  isPrimary: boolean
}

export type ComponentConnectionsList = {
  type: 'ConnectionList'
  items: ComponentConnectionItem[]
  metaData: { isPreset: false; schemaVersion: '1.0'; isHidden: false }
}

export type FormSnapshot = {
  components: ComponentSnapshot[]
  formComponent?: FormRefSnapshot
}

export type ComponentSnapshot = {
  role: string
  config: ComponentConfig
  data: any
  props: any
  layout: any
  componentRef: ComponentRef
  style: any
  componentType: COMPONENT_TYPES
  components: ComponentSnapshot[] | undefined
  skin: string
}

export type ComponentRefAndConfig = {
  componentRef: ComponentRef
  componentConfig: ComponentConfig
}

export type FormRefSnapshot = {
  componentRef: ComponentRef
  config: ComponentConfig
  layout: { width: number; height: number }
}

export enum ControllerType {
  REGISTRATION_FORM = 'registrationForm',
  GET_SUBSCRIBERS = 'getSubscribers',
  WIX_FORMS = 'wixForms',
  MULTI_STEP_FORM = 'multiStepForm',
}

export interface RawComponentStructure {
  role: string
  config: ComponentConfig
  components?: RawComponentStructure[]
  layout: FieldLayout
  style: ComponentStyle | string
  type: COMPONENT_TYPES
  props?: any
  data?: any
  layoutResponsive?: ResponsiveLayout
}

export interface ComponentStructure {
  components?: ComponentStructure[]
  layout: FieldLayout
  style?: ComponentStyle | string
  type: COMPONENT_TYPES
  props?: any
  data?: any
  connections: ComponentConnectionsList
  design?: any
  layoutResponsive?: ResponsiveLayout
}

export type PROPERTIES =
  | PROP_TEXT_ALIGNMENT
  | PROP_ALIGNMENT
  | PROP_LABEL_POSITION
  | PROP_DIRECTION
  | PROP_LAYOUT
  | number

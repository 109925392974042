import { IGeneralField } from '../general-field'
import { GeneralText } from './definitions/general-text'
import { GeneralNumber } from './definitions/general-number'
import { GeneralUrl } from './definitions/general-url'
import { GeneralRadioGroup } from './definitions/general-radio-group'
import { GeneralDatePicker } from './definitions/general-date-picker'
import { GeneralDropdown } from './definitions/general-dropdown'
import { GeneralUploadButton } from './definitions/general-upload-button'
import { GeneralCheckbox } from './definitions/general-checkbox'
import { GeneralSingleCheckbox } from './definitions/general-single-checkbox'
import { GeneralTextBox } from './definitions/general-text-box'
import { GeneralRating } from './definitions/general-rating'
import { GeneralRecaptcha } from './definitions/general-recaptcha'
import { GeneralSubscribe } from './definitions/general-subscribe'
import { GeneralSignature } from './definitions/general-signature'
import { GeneralTimePicker } from './definitions/general-time-picker'

export const GENERAL_FIELDS: IGeneralField[] = [
  new GeneralText(),
  new GeneralNumber(),
  new GeneralUrl(),
  new GeneralRadioGroup(),
  new GeneralDatePicker(),
  new GeneralDropdown(),
  new GeneralUploadButton(),
  new GeneralCheckbox(),
  new GeneralSingleCheckbox(),
  new GeneralSubscribe(),
  new GeneralText(),
  new GeneralTextBox(),
  new GeneralRating(),
  new GeneralRecaptcha(),
  new GeneralSignature(),
  new GeneralTimePicker(),
]
